import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { getStoreCities, getStoreFilters } from '../../api'
import { StoreChainTypeAPI, StoreGroupTypeAPI, StoreTypeTypeAPI } from '../../api/types'
import { Button } from '../../elements/Button'
import { MultiDropdown } from '../../elements/MultiDropdown'
import { MultiDropdownFetch } from '../../elements/MultiDropdownFetch'
import { TextInput } from '../../elements/TextInput'
import { EmptyFiltersToFetchStores, FiltersToFetchStoresType } from '../../pages/Wizard/Step2/Step2'

import styles from './WizardStoresFilters.module.scss'


type WizardStoresFiltersProps = {
  loading: boolean
  setLoading: (loading: boolean) => void
  filtersToFetchStores: FiltersToFetchStoresType
  setFiltersToFetchStores: React.Dispatch<React.SetStateAction<FiltersToFetchStoresType>>
  handleSearchStores: () => void
}


export const WizardStoresFilters = ({
  loading,
  setLoading,
  filtersToFetchStores,
  setFiltersToFetchStores,
  handleSearchStores,
}: WizardStoresFiltersProps) => {
  const i18n = useTranslation()

  // Static data from API
  const [storeTypeFilterOptions, setStoreTypeFilterOptions] = useState<StoreTypeTypeAPI[]>([])
  const [storeChainFilterOptions, setStoreChainFilterOptions] = useState<StoreChainTypeAPI[]>([])
  const [storeGroupFilterOptions, setStoreGroupFilterOptions] = useState<StoreGroupTypeAPI[]>([])


  // Get filters from API
  useEffect(() => {
    getStoreFilters().then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:CoudntGetStoresFilters'))
        return
      }

      setStoreTypeFilterOptions(response.data.types)
      setStoreChainFilterOptions(response.data.chains)
      setStoreGroupFilterOptions(response.data.groups)
      setLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading])


  return (
    <div className={styles.filters}>
      <MultiDropdown<StoreTypeTypeAPI>
        options={storeTypeFilterOptions}
        selected={filtersToFetchStores.storeTypes}
        getOptionLabel={opt => opt.name}
        getOptionId={opt => opt.id.toString()}
        filterBy={opt => opt.name}
        title={i18n.t('Common:Type')}
        height='20rem'
        disabled={loading}
        onChange={v => setFiltersToFetchStores(filters => ({
          ...filters,
          storeTypes: v
        }))}
      />
      <MultiDropdown<StoreChainTypeAPI>
        options={storeChainFilterOptions}
        selected={filtersToFetchStores.storeChains}
        getOptionLabel={opt => opt.name}
        getOptionId={opt => opt.id.toString()}
        filterBy={opt => opt.name}
        title={i18n.t('Common:Chain')}
        height='20rem'
        disabled={loading}
        onChange={v => setFiltersToFetchStores(filters => ({
          ...filters,
          storeChains: v
        }))}
      />
      <MultiDropdownFetch<string>
        selected={filtersToFetchStores.cities}
        getOptionLabel={opt => opt}
        getOptionId={opt => opt}
        fetcher={getStoreCities}
        dataKey='cities'
        title={i18n.t('Common:City')}
        height='20rem'
        disabled={loading}
        onChange={v => setFiltersToFetchStores(filters => ({
          ...filters,
          cities: v
        }))}
      />
      <MultiDropdown<StoreGroupTypeAPI>
        options={storeGroupFilterOptions}
        selected={filtersToFetchStores.storeGroups}
        getOptionLabel={opt => opt.name}
        getOptionId={opt => opt.id.toString()}
        filterBy={opt => opt.name}
        title={i18n.t('Common:Group')}
        height='20rem'
        disabled={loading}
        onChange={v => setFiltersToFetchStores(filters => ({
          ...filters,
          storeGroups: v
        }))}
      />
      <TextInput
        value={filtersToFetchStores.storeNameOrCode}
        title={i18n.t('MissionWizard:StoreNameOrCode')}
        placeholder={i18n.t('MissionWizard:WriteHereStoreNameOrCode')}
        disabled={loading}
        onChange={v => setFiltersToFetchStores(filters => ({
          ...filters,
          storeNameOrCode: v
        }))}
      />
      <Button
        title={i18n.t('Common:Search')}
        handler={handleSearchStores}
        disabled={loading}
        fullHeight
      />
      <Button
        title={i18n.t('Common:Clear')}
        handler={() => setFiltersToFetchStores(EmptyFiltersToFetchStores)}
        disabled={loading}
        variant='secondary'
        fullHeight
      />
    </div>
  )
}
