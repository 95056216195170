import {
  Navigate,
  Route,
  Routes,
  Outlet
} from 'react-router-dom'

import i18n from '../localization'
import { LoginPage } from '../pages/Login/LoginPage'
import { RouteNotFound } from '../pages/RouteNotFound'

import { getPaths } from './routes'


const AuthRouter = () => {
  return (
    <Routes>
      <Route
        path={getPaths.managers._base}
        element={<Outlet />}
      >
        <Route
          path='sign_in'
          element={<LoginPage />}
        />
        <Route
          path=''
          element={<Navigate to={getPaths.managers.signIn} replace />}
        />
        <Route
          path='*'
          element={
            <RouteNotFound
              goToText={i18n.t('GoTo:SignInPage')}
              route={getPaths.managers.signIn}
            />
          }
        />
      </Route>
      {/* If the user tries to access a page from inside (where log in is needed), redirect to sign in */}
      <Route
        path={`${getPaths.brands._base}/*`}
        element={<Navigate to={getPaths.managers.signIn} replace />}
      />
      {/* Otherwise, page not found */}
      <Route
        path='*'
        element={
          <RouteNotFound
            goToText={i18n.t('GoTo:SignInPage')}
            route={getPaths.managers.signIn}
          />
        }
      />
    </Routes>
  )
}

export default AuthRouter
