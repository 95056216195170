import { useCallback, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import avatar from '../../../assets/icons/avatar.svg'
import dashboard from '../../../assets/icons/dashboard.svg'
import logo from '../../../assets/icons/logo.svg'
import logout from '../../../assets/icons/logout.svg'
import mission from '../../../assets/icons/mission.svg'
import sidebarToggle from '../../../assets/icons/sidebarToggle.svg'
import { useUser } from '../../../contexts/User'
import { getPaths } from '../../../routing/routes'

import styles from './Sidebar.module.scss'
import { SidebarItem } from './SidebarItem'


type SideBarProps = {
  isOpen: boolean
}


export const Sidebar = ({ isOpen }: SideBarProps) => {
  const i18n = useTranslation()
  const navigate = useNavigate()
  const { hasPermissions, handleLogout, userData } = useUser()
  const [_, setCookie] = useCookies(['sidebarState'])

  const [showUserTitle, setShowUserTitle] = useState(false)


  const handleSidebarToggle = useCallback(() => {
    // eslint-disable-next-line i18next/no-literal-string
    const newState = isOpen ? 'closed' : 'opened'
    setCookie('sidebarState', newState)
    localStorage.setItem('sidebarState', newState)
  }, [isOpen, setCookie])


  return (
    <div className={`${styles.sidebarContainer} ${isOpen ? styles.opened : styles.closed}`}>
      <button
        className={styles.sidebarToggleContainer}
        onClick={handleSidebarToggle}
      >
        <img
          className={styles.sidebarToggle}
          src={sidebarToggle}
          alt='sidebarToggle'
        />
      </button>
      <div className={styles.sidebarTop}>
        <button
          className={styles.logoContainer}
          onClick={() => navigate(getPaths.managers._base)}
        >
          <img
            src={logo}
            alt='sidebarLogo'
          />
        </button>
        {hasPermissions('missions_viewer') && (
          <SidebarItem
            id='missions'
            icon={mission}
            title={i18n.t('Common:Mission')}
            isOpen={isOpen}
            handler={() => navigate(getPaths.brands.missions.ongoing)}
          />
        )}
        {hasPermissions('dashboard_viewer') && (
          <SidebarItem
            id='dashboard'
            icon={dashboard}
            title={i18n.t('Common:Dashboard')}
            isOpen={isOpen}
            handler={() => navigate(getPaths.brands.dashboard.tab('main_dashboard'))}
          />
        )}
      </div>
      <div className={styles.sidebarBottom}>
        <button
          className={styles.profile}
          onClick={() => setShowUserTitle(!showUserTitle)}
        >
          <img
            src={avatar}
            alt='sidebarAvatar'
          />
          <div className={`${styles.managerInfo} ${showUserTitle && styles.showUserTitle}`}>
            <p>
              {userData?.name}
            </p>
            <span>
              {i18n.t('Template:Manager')}
            </span>
          </div>
        </button>
        <SidebarItem
          icon={logout}
          title={i18n.t('Template:Logout')}
          isOpen={isOpen}
          handler={handleLogout}
        />
      </div>
    </div>
  )
}

