import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getUserData, login, logout } from '../api'
import { PermissionType, UserDataTypeAPI } from '../api/types'
import i18n from '../localization'
import { getPaths } from '../routing/routes'

type UserProviderProps = {
  children: ReactNode
}

type UserContextType = {
  handleLogin: (email: string, password: string, rememberMe: boolean) => void
  handleLogout: () => void
  hasPermissions: (permission: PermissionType) => boolean
  loading: boolean
  isLoggedIn: boolean
  userData: UserDataTypeAPI | undefined
  userDataLoading: boolean
}

const UserContext = createContext({} as UserContextType)
const { Provider } = UserContext


export const UserProvider = ({ children }: UserProviderProps) => {
  const navigate = useNavigate()
  const [isLoggedIn, setisLoggedIn] = useState<boolean>()
  const [authLoading, setAuthLoading] = useState<boolean>(true)
  const [cookies, setCookie, removeCookie] = useCookies(['email', 'authenticationToken', 'sidebarState'])
  const [userData, setUserData] = useState<UserDataTypeAPI>()
  const [userDataLoading, setUserDataLoading] = useState<boolean>(true)


  // Check if the user is logged in when loading the app
  useEffect(() => {
    const email = cookies.email || localStorage.getItem('email')
    const authenticationToken = cookies.authenticationToken || localStorage.getItem('authenticationToken')

    if (!!email && !!authenticationToken) {
      // Set permissions loading to true to avoid showing the user the page before the permissions are loaded
      setUserDataLoading(true)
      setisLoggedIn(true)
    } else {
      setisLoggedIn(false)
    }

    setAuthLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Only run once


  // When the user logs in, get the permissions
  // When the user logs out, remove the permissions
  useEffect(() => {
    if (authLoading) return

    if (!isLoggedIn) {
      setUserData(undefined)
      setUserDataLoading(false)
      return
    }

    setUserDataLoading(true)
    getUserData().then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:ErrorGettingUserInfo'))
        setUserDataLoading(false)
        return
      }
      setUserData(response.data)
      setUserDataLoading(false)
    })
  }, [authLoading, isLoggedIn])


  // Handle login and set the cookies
  const handleLogin = useCallback((
    email: string,
    password: string,
    rememberMe: boolean
  ) => {
    setAuthLoading(true)
    login({ manager: { email, password } }).then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:LoginFailed'))
        setAuthLoading(false)
        return
      }

      setCookie('email', response.data.email)
      setCookie('authenticationToken', response.data.authenticationToken)
      // Set permissions loading to true to avoid showing the user the page before the permissions are loaded
      setUserDataLoading(true)
      setisLoggedIn(true)
      setAuthLoading(false)

      if (rememberMe) {
        localStorage.setItem('email', response.data.email)
        localStorage.setItem('authenticationToken', response.data.authenticationToken)
      } else {
        localStorage.removeItem('email')
        localStorage.removeItem('authenticationToken')
      }

      navigate(getPaths.brands.missions.ongoing)
    })
  }, [setCookie, navigate])


  // Handle logout and remove the cookies
  const handleLogout = useCallback(() => {
    setAuthLoading(true)
    logout().then(() => {
      setisLoggedIn(false)
      setUserData(undefined)
      removeCookie('email')
      removeCookie('authenticationToken')
      localStorage.removeItem('email')
      localStorage.removeItem('authenticationToken')
      setAuthLoading(false)
      navigate(getPaths.managers.signIn)
    })
  }, [removeCookie, navigate])


  // Check if the user has the permission
  const hasPermissions = useCallback((permission: PermissionType) => {
    return userData?.permissions.includes(permission) ?? false
  }, [userData])


  // Create the context value
  const value: UserContextType = useMemo(() => ({
    isLoggedIn: isLoggedIn || false,
    handleLogout,
    handleLogin,
    hasPermissions,
    loading: authLoading || userDataLoading,
    userData,
    userDataLoading
  }), [isLoggedIn, handleLogout, handleLogin, userData, hasPermissions, authLoading, userDataLoading])


  return (
    <Provider value={value}>
      {children}
    </Provider>
  )
}

export const useUser = () => useContext(UserContext)
