import React from 'react'
import { useTranslation } from 'react-i18next'

import PageNotFoundIcon from '../../assets/icons/pageNotFound.svg'

import styles from './NoPermissions.module.scss'


export const NoPermissions = () => {
  const i18n = useTranslation()

  return (
    <div className={styles.noPermissions}>
      <div className={styles.top}>
        <img
          src={PageNotFoundIcon}
          alt='PageNotFoundIcon'
          draggable={false}
        />
        <h2>
          {/*
              README: page needs to be thought further
              Do we put a button to logout? more text?
          */}
          {i18n.t('Error:LooksLikeYouDontHavePermissions')}
        </h2>
      </div>
    </div>
  )
}
