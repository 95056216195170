import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useUser } from '../../contexts/User'
import { Button } from '../../elements/Button'
import { Checkbox } from '../../elements/Checkbox'
import { TextInput } from '../../elements/TextInput'

import styles from './LoginPage.module.scss'


export const LoginPage = () => {
  const i18n = useTranslation()
  const { handleLogin, loading } = useUser()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [rememberMe, setRememberMe] = useState((localStorage.getItem('name') && localStorage.getItem('email') && localStorage.getItem('authenticationToken')) ? true : false)


  const login = useCallback(async () => {
    if (email === '') {
      toast.error(i18n.t('ToastError:FillEmailField'))
      return
    }

    if (password === '') {
      toast.error(i18n.t('ToastError:FillPasswordField'))
      return
    }

    handleLogin(email, password, rememberMe)
  }, [email, password, rememberMe, i18n, handleLogin])


  return (
    <div className={styles.loginPage}>
      <div className={styles.signInContainer}>
        <h1>
          {i18n.t('Login:SignIn')}
        </h1>
        <TextInput
          title={i18n.t('Login:Email')}
          placeholder={i18n.t('Login:WriteYourEmailHere')}
          value={email}
          onChange={v => setEmail(v)}
          onEnter={login}
          // eslint-disable-next-line i18next/no-literal-string
          type='email'
        />
        <TextInput
          title={i18n.t('Login:Password')}
          placeholder={i18n.t('Login:WriteYourPasswordHere')}
          value={password}
          onChange={v => setPassword(v)}
          onEnter={login}
          // eslint-disable-next-line i18next/no-literal-string
          type={showPassword ? 'text' : 'password'}
        />
        <Checkbox
          text={i18n.t('Login:ShowPassword')}
          checked={showPassword}
          handler={() => { setShowPassword(!showPassword) }}
        />
        <Checkbox
          text={i18n.t('Login:RememberMe')}
          checked={rememberMe}
          handler={() => { setRememberMe(!rememberMe) }}
        />
        <Button
          title={i18n.t('Login:Login')}
          handler={login}
          fullWidth
          loading={loading}
        />
      </div>
    </div>
  )
}
