import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { MissionTypeAPI } from '../../api/types'
import { PercentageBar } from '../../elements/PercentageBar'
import { ListCard } from '../ListCard'

import styles from './MissionCard.module.scss'


type MissionCardProps = {
  mission: MissionTypeAPI
  grey?: boolean
  handler: (() => void)
}

export const MissionCard = ({
  mission,
  grey,
  handler
}: MissionCardProps) => {
  const i18n = useTranslation()

  return (
    <ListCard
      grey={grey}
      handler={handler}
    >
      <div className={styles.missionCard}>
        <div className={styles.left}>
          <h3 className={mission.title ? undefined : styles.italic}>
            {mission.title ? mission.title : i18n.t('Mission:TitleNotDefined')}
          </h3>
          <h6 className={`${styles.description} ${!mission.description && styles.italic}`}>
            {mission.description ? mission.description : i18n.t('Mission:DescriptionNotDefined')}
          </h6>
          <PercentageBar
            percentage={mission.progressCompleted || 0}
            percentage2={mission.progressToValidate}
          />
        </div>
        <div className={styles.right}>
          <h5 className={`${styles.text} ${!mission.startDate && styles.italic}`}>
            {`${i18n.t('Common:From')}: ${mission.startDate ? dayjs(mission.startDate).toString() : i18n.t('Common:NotDefined')}`}
          </h5>
          <h5 className={`${styles.text} ${!mission.endDate && styles.italic}`}>
            {`${i18n.t('Common:Until')}: ${mission.endDate ? dayjs(mission.endDate).toString() : i18n.t('Common:NotDefined')}`}
          </h5>
          <h5 className={`${styles.text} ${!mission.storesCount && styles.italic}`}>
            {`${i18n.t('Mission:NumberOfStores')}: ${mission.storesCount || 0}`}
          </h5>
        </div>
      </div>
    </ListCard>
  )
}
