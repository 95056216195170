import dayjs, { Dayjs } from 'dayjs'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getPlacesOrStores, GetPlacesOrStoresFiltersType } from '../../../api'
import {
  MetadataTypeAPI,
  MissionStatusType,
  MissionUpdateTypeAPI,
  PlaceOrStoreDataTypeAPI,
  StoreChainTypeAPI,
  StoreGroupTypeAPI,
  StoreTypeTypeAPI
} from '../../../api/types'
import { Map } from '../../../components/Map'
import { WizardStoresFilters } from '../../../components/WizardStoresFilters'
import { Button } from '../../../elements/Button'
import { DateTimePicker } from '../../../elements/DateTimePicker'
import { Scroll } from '../../../elements/Scroll'
import { parseDayJs } from '../../../utils/dates'
import { WizardContextType } from '../Wizard'

import styles from './Step2.module.scss'


export type FiltersToFetchStoresType = {
  storeTypes: StoreTypeTypeAPI[]
  storeChains: StoreChainTypeAPI[]
  storeGroups: StoreGroupTypeAPI[]
  cities: string[]
  storeNameOrCode: string
}

export const EmptyFiltersToFetchStores: FiltersToFetchStoresType = {
  storeTypes: [],
  storeChains: [],
  storeGroups: [],
  cities: [],
  storeNameOrCode: ''
}


export const Step2 = forwardRef(() => {
  const i18n = useTranslation()

  const { mission, setStep, stepRef } = useOutletContext<WizardContextType>()

  const [loading, setLoading] = useState(true)
  const [searchStores, setSearchStores] = useState<PlaceOrStoreDataTypeAPI[]>([])
  const [searchStoresSelectedIds, setSearchStoresSelectedIds] = useState<PlaceOrStoreDataTypeAPI['id'][]>([])
  const [assignStores, setAssignStores] = useState<PlaceOrStoreDataTypeAPI[]>(mission.temporaryPlaceOrStores || [])
  const [assignStoresSelectedIds, setAssignStoresSelectedIds] = useState<PlaceOrStoreDataTypeAPI['id'][]>([])
  const [searchStoresMetadata, setSearchStoresMetadata] = useState<MetadataTypeAPI>()

  const assignStoresIds = useMemo(() => assignStores.map(s => s.id), [assignStores])

  // Store filters
  const [filtersToFetchStores, setFiltersToFetchStores] = useState<FiltersToFetchStoresType>(EmptyFiltersToFetchStores)

  // Mission dates
  const [previewDate, setPreviewDate] = useState<Dayjs | null>(parseDayJs(mission.previewDate))
  const [startDate, setStartDate] = useState<Dayjs | null>(parseDayJs(mission.startDate))
  const [endDate, setEndDate] = useState<Dayjs | null>(parseDayJs(mission.endDate))

  // All search stores selected
  const allSearchStoresSelected = useMemo(() => {
    if (searchStores.length === 0) return false

    const storesIds = searchStores.map(s => s.id)
    return (storesIds.length === searchStoresSelectedIds.length && storesIds.every(id => searchStoresSelectedIds.includes(id)))
  }, [searchStoresSelectedIds, searchStores])


  // All assign stores selected
  const allAssignStoresSelected = useMemo(() => {
    if (assignStores.length === 0) return false

    const storesIds = assignStores.map(s => s.id)
    return (storesIds.length === assignStoresSelectedIds.length && storesIds.every(id => assignStoresSelectedIds.includes(id)))
  }, [assignStoresSelectedIds, assignStores])


  // Tell wizard that this is step 2
  useEffect(() => {
    setStep(2)
  }, [setStep])


  // Functions to be called by the wizard
  useImperativeHandle(stepRef, () => ({
    updateData(newStatus: MissionStatusType): MissionUpdateTypeAPI {
      return({mission: {
        placeAndStoreIds: assignStores.length === 0 ? null : assignStores.map(s => s.id),
        previewDate: (previewDate?.isValid()) ? previewDate.toISOString() : undefined,
        startDate: (startDate?.isValid()) ? startDate.toISOString() : undefined,
        endDate: (endDate?.isValid()) ? endDate.toISOString() : undefined,
        status: newStatus
      }})
    },

    getWarnings(): string[] {
      const newWarnings = []
      if (assignStores.length === 0)
        newWarnings.push(i18n.t('MissionWizard:WarningNoStoresAssigned'))
      if (!previewDate?.isValid())
        newWarnings.push(i18n.t('MissionWizard:WarningPreviewDateMissing'))
      if (!startDate?.isValid())
        newWarnings.push(i18n.t('MissionWizard:WarningStartDateMissing'))
      if (!endDate?.isValid())
        newWarnings.push(i18n.t('MissionWizard:WarningEndDateMissing'))
      if (previewDate?.isValid() && startDate?.isValid() && previewDate.isAfter(startDate))
        newWarnings.push(i18n.t('MissionWizard:WarningPreviewDateAfterStartDate'))
      if (startDate?.isValid() && endDate?.isValid() && (startDate.isAfter(endDate) || startDate.isSame(endDate)))
        newWarnings.push(i18n.t('MissionWizard:WarningStartDateAfterEndDate'))
      if (previewDate?.isValid() && endDate?.isValid() && previewDate.isAfter(endDate))
        newWarnings.push(i18n.t('MissionWizard:WarningPreviewDateAfterEndDate'))
      if (endDate?.isValid() && endDate.isBefore(dayjs()))
        newWarnings.push(i18n.t('MissionWizard:WarningEndDateBeforeToday'))
      return newWarnings
    }
  }))


  // Fetch stores from the API
  const fetchStores = useCallback((page = 1) => {
    if (loading) return

    const filters: GetPlacesOrStoresFiltersType = { page }
    if (filtersToFetchStores.storeTypes.length > 0) filters.placeTypeIds = filtersToFetchStores.storeTypes.map(st => st.id)
    if (filtersToFetchStores.storeChains.length > 0) filters.storeChainIds = filtersToFetchStores.storeChains.map(sc => sc.id)
    if (filtersToFetchStores.storeGroups.length > 0) filters.storeGroupIds = filtersToFetchStores.storeGroups.map(sg => sg.id)
    if (filtersToFetchStores.cities.length > 0) filters.cities = filtersToFetchStores.cities
    if (filtersToFetchStores.storeNameOrCode !== '') filters.nameOrCode = filtersToFetchStores.storeNameOrCode

    setLoading(true)
    getPlacesOrStores(filters).then(response => {
      setLoading(false)
      if (!response.success) {
        toast.error(i18n.t('ToastError:CoundtGetStores'))
        return
      }

      setSearchStoresMetadata(response.data.metadata)
      setSearchStores(stores => {
        if (page === 1) return response.data.data

        const oldIds = stores.map(s => s.id)
        return [...stores, ...response.data.data.filter(s => !oldIds.includes(s.id))]
      })
    })
  }, [filtersToFetchStores, i18n, loading])


  // Select or deselect specific store from the search stores list
  const handleSelectSearchStore = useCallback((store: PlaceOrStoreDataTypeAPI) => {
    setSearchStoresSelectedIds(old => old.includes(store.id)
      ? old.filter(id => id !== store.id)
      : [...old, store.id]
    )
  }, [])


  // Select or deselect specific store from the assign stores list
  const handleSelectAssignStore = useCallback((store: PlaceOrStoreDataTypeAPI) => {
    setAssignStoresSelectedIds(old => old.includes(store.id)
      ? old.filter(id => id !== store.id)
      : [...old, store.id]
    )
  }, [])


  // Select or deselect all search stores
  const handleToggleSelectAllSearchStores = useCallback(() => {
    setSearchStoresSelectedIds(() => {
      if (allSearchStoresSelected) return []
      return searchStores.map(s => s.id)
    })
  }, [allSearchStoresSelected, searchStores])


  // Select or deselect all assign stores
  const handleToggleSelectAllAssignStores = useCallback(() => {
    setAssignStoresSelectedIds(() => {
      if (allAssignStoresSelected) return []
      return assignStores.map(s => s.id)
    })
  }, [allAssignStoresSelected, assignStores])


  // Assign selected search stores to the mission
  const handleAssignSearchStores = useCallback(() => {
    const storesToAssign = searchStores.filter(s => searchStoresSelectedIds.includes(s.id))
    setAssignStores(old => {
      const oldIds = old.map(s => s.id)
      return [...old, ...storesToAssign.filter(s => !oldIds.includes(s.id))]
    })
    setSearchStoresSelectedIds([])
  }, [searchStores, searchStoresSelectedIds])


  // Unassign selected assign stores from the mission
  const handleUnassignSearchStores = useCallback(() => {
    setAssignStores(old => old.filter(s => !assignStoresSelectedIds.includes(s.id)))
    setAssignStoresSelectedIds([])
  }, [assignStoresSelectedIds])


  return (
    <div className={styles.step2}>
      <h2>
        {i18n.t('MissionWizard:StoresAssignedToMission')}
      </h2>
      <WizardStoresFilters
        loading={loading}
        setLoading={setLoading}
        filtersToFetchStores={filtersToFetchStores}
        setFiltersToFetchStores={setFiltersToFetchStores}
        handleSearchStores={fetchStores}
      />
      <div className={styles.stores}>
        <div className={styles.storesColumn}>
          <div className={styles.storesHeader}>
            <h3>
              {i18n.t('MissionWizard:SearchStores')}
            </h3>
            <h4 className={styles.pill}>
              {`${searchStores.length} / ${searchStoresMetadata?.itemCount || '0'} ${searchStoresMetadata?.itemCount === 1 ? i18n.t('Common:Store') : i18n.t('Common:Stores')}`}
            </h4>
          </div>
          <div className={styles.storesBody}>
            <div className={styles.selectAllContainer}>
              <Button
                handler={handleToggleSelectAllSearchStores}
                title={i18n.t('Common:SelectAll')}
                checkbox
                checked={allSearchStoresSelected}
                variant='secondary'
                fullWidth
                fullHeight
              />
            </div>
            <Scroll<PlaceOrStoreDataTypeAPI>
              options={searchStores}
              handleFetch={() => fetchStores(searchStoresMetadata?.nextPage || 1)}
              hasMoreData={!!searchStoresMetadata?.nextPage}
              height={'30rem'}
              finalText={i18n.t('Common:NoMoreStores')}
              getOptionKey={store => store.id}
              getOptionComponent={store => (
                <Button
                  title={store.name}
                  handler={() => handleSelectSearchStore(store)}
                  fullWidth
                  textLeft
                  disabled={assignStoresIds.includes(store.id)}
                  checkbox
                  checked={searchStoresSelectedIds.includes(store.id)}
                  variant='secondary'
                  noShadow
                />
              )}
            />
            <Button
              title={i18n.t('MissionWizard:AddToMission', {numStores: searchStoresSelectedIds.length})}
              handler={handleAssignSearchStores}
              variant='primaryWhite'
              disabled={!searchStoresSelectedIds.length}
              fullWidth
            />
          </div>
        </div>
        <div className={styles.storesColumn}>
          <div className={styles.storesHeader}>
            <h3>
              {i18n.t('MissionWizard:AssignedStores')}
            </h3>
            <h4 className={styles.pill}>
              {`${assignStores.length} ${assignStores.length === 1 ? i18n.t('Common:Store') : i18n.t('Common:Stores')}`}
            </h4>
          </div>
          <div className={styles.storesBody}>
            <div className={styles.selectAllContainer}>
              <Button
                handler={handleToggleSelectAllAssignStores}
                title={i18n.t('Common:SelectAll')}
                checkbox
                checked={allAssignStoresSelected}
                variant='secondary'
                fullWidth
                fullHeight
              />
            </div>
            <Scroll<PlaceOrStoreDataTypeAPI>
              options={assignStores}
              height={'30rem'}
              finalText={i18n.t('Common:NoMoreStores')}
              getOptionKey={store => store.id}
              getOptionComponent={store => (
                <Button
                  title={store.name}
                  handler={() => handleSelectAssignStore(store)}
                  fullWidth
                  textLeft
                  checkbox
                  checked={assignStoresSelectedIds.includes(store.id)}
                  variant='secondary'
                  noShadow
                />
              )}
            />
            <Button
              title={i18n.t('MissionWizard:RemoveFromMission', {numStores: assignStoresSelectedIds.length})}
              handler={handleUnassignSearchStores}
              variant='primaryWhite'
              disabled={!assignStoresSelectedIds.length}
              fullWidth
            />
          </div>
        </div>
        <div className={styles.storesColumn}>
          <div className={styles.storesHeader}>
            <h3>
              {i18n.t('MissionWizard:AssignedStoresMap')}
            </h3>
          </div>
          <div className={styles.mapContainer}>
            <Map
              markers={assignStores.map(s => ({
                position: [s.location.latitude, s.location.longitude],
                popUp: s.name,
                status: 'preview'
              }))}
            />
          </div>
        </div>
      </div>
      <h2>
        {i18n.t('MissionWizard:MissionDates')}
      </h2>
      <div className={styles.pickers}>
        <DateTimePicker
          label={i18n.t('Mission:PreviewDate')}
          value={previewDate}
          onChange={v => setPreviewDate(v)}
          timeSelection
        />
        <DateTimePicker
          label={i18n.t('Mission:StartDate')}
          value={startDate}
          onChange={v => setStartDate(v)}
          minDateTime={dayjs(previewDate)}
          timeSelection
        />
        <DateTimePicker
          label={i18n.t('Mission:EndDate')}
          value={endDate}
          onChange={v => setEndDate(v)}
          disablePast
          // eslint-disable-next-line i18next/no-literal-string
          minDateTime={dayjs(startDate).add(1, 'second')}
          timeSelection
        />
      </div>
    </div>
  )
})
