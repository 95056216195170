import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { sideBarItemsIds, sideBarItemsPaths } from '../../../../routing/routes'

import styles from './SidebarItem.module.scss'


type SidebarItemProps = {
  id?: sideBarItemsIds
  icon: string
  title: string
  isOpen: boolean
  handler: () => void
}


export const SidebarItem = ({
  id,
  isOpen,
  handler,
  icon,
  title
}: SidebarItemProps) => {
  const location = useLocation()

  const isActive = useMemo(() => {
    if (!id) return false

    return sideBarItemsPaths[id].some(path => location.pathname.includes(path))
  }, [location, id])


  return (
    <button
      className={`${styles.sidebarItem} ${isActive && styles.active}`}
      onClick={handler}
    >
      <img
        className={styles.icon}
        src={icon}
        alt='sidebarItem'
      />
      <p className={`${isOpen && styles.open}`}>
        {title}
      </p>
    </button>
  )
}
