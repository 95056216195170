import React, { ReactNode, useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { RouteNotFound } from '../../../pages/RouteNotFound'
import { isPositiveInteger } from '../../../utils/regexs'
import { Loading } from '../Loading'


type IdParams = {
  id: string
}

const isIdParams = (params: Record<string, string | undefined>): params is IdParams => {
  return params.id !== undefined;
}

type CheckIdParamProps = {
  errorText: string
  goToText: string
  redirectPath: string
  children?: (id: number) => ReactNode
}

export type CheckIdParamContextType = {
  id: number
}


export const CheckIdParam = ({
  errorText,
  goToText,
  redirectPath,
  children
}: CheckIdParamProps) => {
  const navigate = useNavigate()
  const params = useParams()

  const [loading, setLoading] = useState(true)
  const [invalidPath, setInvalidPath] = useState(false)


  // Check if the id parameter is present and if it is an acceptable one, and act accordingly
  useEffect(() => {
    setLoading(true)
    // If the id parameter is not present, redirect to the specified path
    if (!isIdParams(params)) {
      toast.error(errorText, {autoClose: 2600, pauseOnHover: false})
      navigate(redirectPath)
      return
    }

    // If the id parameter is not a positive integer, set invalidPath to true and show the RouteNotFound component
    // Else (is a positive integer) set invalidPath to false and show the children component / Outlet
    const { id } = params
    if (isPositiveInteger(id)) setInvalidPath(false)
    else setInvalidPath(true)

    setLoading(false)
  }, [params, errorText, goToText, redirectPath, navigate])


  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        invalidPath ? (
          <RouteNotFound
            goToText={goToText}
            route={redirectPath}
          />
        ) : (
          /* eslint-disable @typescript-eslint/no-non-null-assertion */
          children ? (
            children(parseInt(params.id!, 10))
          ) : (
            <Outlet context={{ id: parseInt(params.id!, 10) } satisfies CheckIdParamContextType} />
          )
          /* eslint-enable @typescript-eslint/no-non-null-assertion */
        )
      )}
    </>
  )
}
