import { createTheme, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useMemo } from 'react'
import { CookiesProvider, useCookies } from 'react-cookie'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { Footer } from './components/shared/Footer'
import { Header } from './components/shared/Header'
import { Loading } from './components/shared/Loading'
import { Sidebar } from './components/shared/Sidebar'
import { UserProvider, useUser } from './contexts/User'
import AuthRouter from './routing/Auth'
import AppRouter from './routing/index'
import LookerRouter from './routing/Looker'

import 'react-toastify/dist/ReactToastify.css'
import './styles/global.scss'

import './localization'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const theme = createTheme({
  typography: {
    // eslint-disable-next-line i18next/no-literal-string
    fontFamily: '"Space Grotesk", sans-serif'
  }
})


const AppWithContexts = () => {
  const { isLoggedIn, userDataLoading } = useUser()
  const [cookies] = useCookies(['sidebarState'])

  const sideBarState = useMemo(() => {
    // eslint-disable-next-line i18next/no-literal-string
    return cookies.sidebarState || localStorage.getItem('sidebarState') || 'opened'
  }, [cookies.sidebarState])

  return (
    <>
      {isLoggedIn ? (
        <Sidebar isOpen={sideBarState === 'opened'} />
      ) : (
        <Header />
      )}
      <div className={`page ${isLoggedIn && 'loggedIn'} ${sideBarState === 'opened' ? 'opened' : 'closed'}`}>
        <div className={'content'}>
        {/* README: If the user is in the Looker dashboard, render the LookerRouter
            This is temporary!! When the internal dashboard is ready, this will be removed */}
        {/* eslint-disable-next-line i18next/no-literal-string */}
        {window.location.pathname.includes('mission_dashboard') ? (
          <LookerRouter />
        ) : userDataLoading ? (
          <Loading />
          ) : (isLoggedIn ? (
            <AppRouter />
          ) : (
            <AuthRouter />
          )
          )}
        </div>
        <Footer />
      </div>
    </>
  )
}


root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ToastContainer />
          <UserProvider>
            <AppWithContexts />
          </UserProvider>
        </BrowserRouter>
      </ThemeProvider>
    </CookiesProvider>
  </LocalizationProvider>
)
