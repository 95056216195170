import React, {
  ReactNode,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { RouteNotFound } from '../../../pages/RouteNotFound'
import { Loading } from '../Loading'


type CheckLiteralStringParamProps = {
  param: string
  options: string[]
  errorText: string
  goToText: string
  redirectPath: string
  children?: ReactNode
}


export const CheckLiteralStringParam = ({
  param,
  options,
  errorText,
  goToText,
  redirectPath,
  children
}: CheckLiteralStringParamProps) => {
  const navigate = useNavigate()
  const params = useParams()

  const [loading, setLoading] = useState(true)
  const [invalidPath, setInvalidPath] = useState(false)


  // Check if the requested parameter is present
  const isLiteralStringParams = useMemo(() => {
    return params[param] !== undefined
  }, [params, param])


  // Check if the requested parameter is present and if it is an acceptable one, and act accordingly
  useEffect(() => {
    setLoading(true)
    // If the requested parameter is not present, redirect to the specified path
    if (!isLiteralStringParams) {
      toast.error(errorText, {autoClose: 2600, pauseOnHover: false})
      navigate(redirectPath)
      return
    }

    // If the requested parameter is not an acceptable one, set invalidPath to true and show the RouteNotFound component
    // Else (is acceptable) set invalidPath to false and show the children component / Outlet
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (options.includes(params[param]!)) setInvalidPath(false)
    else setInvalidPath(true)

    setLoading(false)
  }, [params, errorText, isLiteralStringParams, options, param, redirectPath, navigate])


  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        invalidPath ? (
          <RouteNotFound
            goToText={goToText}
            route={redirectPath}
          />
        ) : (
          children ? (
            children
          ) : (
            <Outlet />
          )
        )
      )}
    </>
  )
}
