import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { createMission, getMissions, GetMissionsParamsType, missionState } from '../../api'
import { MissionTypeAPI } from '../../api/types'
import { MissionCard } from '../../components/MissionCard'
import { Loading } from '../../components/shared/Loading'
import { useUser } from '../../contexts/User'
import { Button } from '../../elements/Button'
import { TabIndicator } from '../../elements/TabIndicator'
import { TextInput } from '../../elements/TextInput'
import { getPaths } from '../../routing/routes'
import usePagyRequest from '../../utils/usePagyRequest'
import useSearchParams from '../../utils/useSearchParams'

import styles from './MissionsPage.module.scss'


type MissionsPageProps = {
  state: missionState
}


export const MissionsPage = ({ state }: MissionsPageProps) => {
  const i18n = useTranslation()
  const navigate = useNavigate()
  const { hasPermissions } = useUser()
  const { getSearchParams, searchParams, updateSearchParams, setSearchParams } = useSearchParams()

  const [missions, setMissions] = useState<MissionTypeAPI[]>([])
  const [missionFilterText, setMissionFilterText] = useState(searchParams.get('name') || '')
  const [creatingMission, setCreatingMission] = useState<boolean>(false)


  const missionName = useMemo(() => searchParams.get('name'), [searchParams])


  // Deals with fetching the missions and paginating them
  const { loading, pagyIndicator } = usePagyRequest<GetMissionsParamsType, MissionTypeAPI>({
    requestFunction: getMissions,
    requestParams: { state, name: missionName, count: 10 },
    setData: setMissions,
    errorText: i18n.t('Mission:ErrorFetchingMissions')
  })


  // Handler for creating a new mission
  const handleNewMission = useCallback(() => {
    setCreatingMission(true)
    createMission().then(response => {
      setCreatingMission(false)
      if (!response.success) {
        toast.error(i18n.t('ToastError:MissionNotCreated'))
        return
      }

      navigate(getPaths.brands.wizard.step_1(response.data.id))
    })
  }, [i18n, navigate])


  // Handler for clicking on a mission card
  const missionCardHandler = useCallback((mission: MissionTypeAPI) => {
    mission.status === 'created'
      ? navigate(getPaths.brands.mission.details(mission.id))
      : navigate(getPaths.brands.wizard[mission.status](mission.id))
  }, [navigate])


  // Handle the mission name filter debounce
  const handleDebounceNameFilter = useCallback((v: string) => {
    if (v === '') {
      const newSearchParams = getSearchParams()
      newSearchParams.delete('name')
      newSearchParams.set('page', '1')
      setSearchParams(newSearchParams)
    } else {
      updateSearchParams({ name: v, page: '1' })
    }
  }, [updateSearchParams, setSearchParams, getSearchParams])


  return (
    <div className={styles.missions}>
      <div className={styles.top}>
        <h1>
          {i18n.t('Common:Missions')}
        </h1>
        {hasPermissions('mission_creator') && (
          <Button
            title={i18n.t('Mission:NewMission')}
            handler={handleNewMission}
            loading={creatingMission}
          />
        )}
      </div>
      <TabIndicator
        currentTab={state}
        disabled={loading}
        tabsLeft={[
          {
            id: 'ongoing',
            title: i18n.t('Mission:Ongoing'),
            onClick: () => navigate(getPaths.brands.missions.ongoing)
          },
          {
            id: 'before_start',
            title: i18n.t('Mission:BeforeStart'),
            onClick: () => navigate(getPaths.brands.missions.before_start)
          },
          {
            id: 'completed',
            title: i18n.t('Mission:Completed'),
            onClick: () => navigate(getPaths.brands.missions.completed)
          }
        ]}
        tabsRight={[
          {
            id: 'in_the_making',
            title: i18n.t('Mission:InTheMaking'),
            onClick: () => navigate(getPaths.brands.missions.in_the_making)
          }
        ]}
      />
      <div className={styles.missionFilter}>
        <TextInput
          value={missionFilterText}
          placeholder={i18n.t('Mission:Filter:WriteHereMissionName')}
          disabled={loading || creatingMission}
          onChange={setMissionFilterText}
          debounceHandler={handleDebounceNameFilter}
          pill
          search
          customWidth='25rem'
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        missions.length === 0 ? (
          <div className={styles.noMissions}>
            <h3>
              {i18n.t('Mission:NoMissions')}
            </h3>
          </div>
        ) : (
          <>
            <div className={styles.missionsList}>
              {missions.map((mission, index) => (
                <MissionCard
                  key={`missionCard_${mission.id}`}
                  mission={mission}
                  grey={index % 2 === 0}
                  handler={() => missionCardHandler(mission)}
                />
              ))}
            </div>
            {pagyIndicator}
          </>
        )
      )}
    </div>
  )
}
