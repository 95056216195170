import React from 'react'
import { useNavigate } from 'react-router-dom'

import LogoIcon from '../../../assets/icons/logo.svg'
import { getPaths } from '../../../routing/routes'

import styles from './Header.module.scss'


export const Header = () => {
  const navigate = useNavigate()

  return (
    <header className={styles.header}>
      <button 
        onClick={() => navigate(getPaths.managers.signIn)}
        className={styles.logoContainer}
      >
        <img
          src={LogoIcon}
          alt='icon'
          className={styles.logo}
          draggable={false}
        />
      </button>
    </header>
  )
}
